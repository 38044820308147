<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <div v-if="loadingVuex" class="ma-0 pa-0">
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>

      <div v-if="!loadingVuex" class="ma-0 pa-0 d-flex">
        <!-- section 1 -->
        <div class="d-flex flex-column text-right pr-3 pt-4">
          <span
            class=" text-white  font-weight-bold font-size-sm d-none d-md-inline "
          >
            {{ currentUserPersonalInfo.first_name }}
          </span>
          <span
            class=" text-white font-weight-bolder font-size-sm d-none d-md-inline"
          >
            {{ currentUserPersonalInfo.last_name }}
          </span>
        </div>

        <v-divider vertical dark></v-divider>

        <span
          v-if="hasPhoto && !this.currentUserPersonalInfo.is_from_admission"
          class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-80 px-5 py-2 rounded d-inline d-md-none"
        >
          <v-avatar size="45">
            <v-img :src="`${currentUserPersonalInfo.photo}`"></v-img>
          </v-avatar>
        </span>
        <span
          v-else
          class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-80 px-5 py-2 rounded d-inline d-md-none"
        >
          {{ getFirstNameInitial }}
        </span>
        <!-- section 3 -->
        <span class="d-flex flex-column text-left pl-3 pt-4">
          <span
            class="text-white opacity-65 font-weight-bolder font-size-sm d-none d-md-inline "
            >{{ currentUserPersonalInfo.grade }}
            <!-- TODO: Remove display none -->
            <span class="d-none">
              ({{ currentUserPersonalInfo.section_group }})</span
            >
          </span>
          <span
            class="text-white opacity-65 text-left font-weight-bolder font-size-sm d-none d-md-inline"
            >{{
              currentUserPersonalInfo.speciality_id == 8
                ? "Tercer Ciclo"
                : currentUserPersonalInfo.speciality
            }}
          </span>
        </span>

        <span
          v-if="hasPhoto"
          class="ml-4 symbol-label font-size-h5 font-weight-bold text-white py-2 rounded d-xl-inline d-none"
        >
          <v-avatar size="45">
            <v-img :src="`${currentUserPersonalInfo.photo}`"></v-img>
          </v-avatar>
        </span>
        <span
          v-else
          class="ml-2 symbol-label font-size-h5 font-weight-bold text-white bg-white-o-80 px-5 py-2 rounded d-xl-inline d-none"
        >
          {{ getFirstNameInitial }}
        </span>
      </div>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Perfil del estudiante
          <!-- <small class="text-muted font-size-sm ml-2">
            {{ currentUserPersonalInfo.code }}
          </small> -->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center flex-column mt-15 ">
          <!-- <v-avatar v-if="hasPhoto" size="150" class="mb-4">
            <v-img :src="`${currentUserPersonalInfo.photo}`"></v-img>
          </v-avatar> -->
          <v-img
            width="140"
            v-if="hasPhoto && !this.currentUserPersonalInfo.is_from_admission"
            :src="`${currentUserPersonalInfo.photo}`"
            :lazy-src="`${currentUserPersonalInfo.photo}`"
            aspect-ratio="0.80"
            class="grey lighten-2 rounded-lg mb-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div v-else class="symbol symbol-100 mb-5">
            <div
              class="symbol-label font-size-h1 font-weight-bold bg-blue text-black"
            >
              {{ getFirstNameInitial }}
            </div>
          </div>
          <div class="d-flex flex-column">
            <p class="text-body-1 font-weight-medium text-center mb-1">
              <span class="font-weight-bold">Código: </span>
              {{ currentUserPersonalInfo.code }}
            </p>
            <p
              v-if="!this.currentUserPersonalInfo.is_from_admission"
              class="text-body-1 font-weight-medium text-center"
            >
              <span class="font-weight-bold">NIE: </span>
              {{ currentUserPersonalInfo.nie }}
            </p>
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary text-center"
            >
              <span>{{ currentUserPersonalInfo.first_name }}</span> <br />
              <span>{{ currentUserPersonalInfo.last_name }}</span>
            </a>
            <p class="text-body-1 font-weight-medium text-center mt-2">
              {{ currentUserPersonalInfo.grade }}
              <!-- TODO: Remove display none -->
              <span class="d-none">
                {{ currentUserPersonalInfo.section_group }},
                {{
                  currentUserPersonalInfo.speciality_id == 8
                    ? "Tercer Ciclo"
                    : currentUserPersonalInfo.technical_group
                }}</span
              >
            </p>
            <div class="navi mt-2">
              <div href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-center text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </div>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Cerrar sesión
            </button>
          </div>
        </div>
        <!--end::Header-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters } from "vuex";

export default {
  name: "KTQuickUser",

  data() {
    return {
      loadingVuex: true,
    };
  },

  watch: {
    currentUserPersonalInfo: {
      handler(newValue) {
        // Check if the object has any properties (i.e., is not empty)
        if (Object.keys(newValue).length > 0) {
          this.loadingVuex = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },

  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then((data) => {
        this.sweetAlertResponse(data);
        this.fireToast({
          icon: data.status ? "success" : "error",
          title: data.message,
        });
        if (data.status) {
          this.$router.push({ name: "login" });
        }
      });
      sessionStorage.clear();
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    hasPhoto() {
      return !!this.currentUserPersonalInfo.photo;
    },

    getFirstNameInitial() {
      if ("first_name" in this.currentUserPersonalInfo) {
        return this.currentUserPersonalInfo.first_name.charAt(0).toUpperCase();
      }
    },
  },
};
</script>

<style scoped>
.lower {
  text-transform: capitalize !important;
}
</style>
